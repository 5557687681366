import styled from "styled-components";

import heroBackground from '../../images/bgg.png'
import heroBackgroundMobile from '../../images/bggm.png'
import banner from '../../images/banner-hyapes.png'

const Wrapper = styled.section`
`

const H2 = styled.h2`
  text-align: center;
  margin: 0 auto 50px;
`;

const Background = styled.div`
  background-image: url(${ heroBackground });
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 44%;

  @media all and (max-width: 767px) {
    background-image: url(${ heroBackgroundMobile });
    // background-size: cover;
    // padding-top: initial;
    // height: 100vh;
  }
`

const HeroSection = () => {
  return (
    <Wrapper id="hero">
      <img src={banner} style={{margin: "5% auto 50px", maxWidth: "75%"}} />
      <Background>
      </Background>
    </Wrapper>
  );
};

export default HeroSection;
