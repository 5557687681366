import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Home/Home";
import Navbar from "./Components/Navbar/Navbar"
import MyCollection from "./Components/MyCollection/MyCollection"
import Summary from "./Components/Summary/Summary"

import './App.scss'

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        {/* <Route path="/mycollection">
          <MyCollection />
        </Route> */}
        {/* <Route path="/summary">
          <Summary />
        </Route> */}
      </Switch>
    </>
  );
}

export default App;
