import styled from "styled-components";

const Wrapper = styled.div`
  background-color: #fff;
  .grid-middle {
    margin-bottom: 35px;
  }
  @media all and (max-width: 767px) {
    .grid-middle {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-bottom: 10px;
    }
  }
`;

const Content = styled.p`
  margin-bottom: 6rem;
`

const H2 = styled.h2``

const MapItemContainer = styled.div`
  @media all and (max-width: 767px) {
    margin-bottom: 2rem;
  }
`

const Diamond = styled.div`
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-bottom-color: #3C3C3C;
  position: relative;
  top: -50px;

  p {
    color: #fff;
    position: absolute;
    top: 35px;
    left: -15px;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    left: -50px;
    top: 50px;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-top-color: #3C3C3C;
  }
  @media all and (max-width: 767px) {
    position: relative;
    display: flex;
    margin: 0 auto;

  }
`

const SpecialDiamond = styled(Diamond)`
  p {
    left: -22px;
  }
`

const RoadMap = () => {
  const roadmapArray = [
    {
      text: "10 NFT giveaway across social platforms. Further marketing push.",
      percent: "10%",
    },
    {
      text: "Aaron's Community Tattoo. GingerBreadBoys Utility Announcement. $1000 community wallet drop.",
      percent: "25%",
    },
    {
      text: "$2000 Donation to Children's Charity, Voted by the Community.",
      percent: "50%",
    },

    {
      text: "Initial Search for partnerships with NFT Marketplaces.",
      percent: "60%",
    },
    {
      text: "$5,000 BNB Giveaway to One Holder.",
      percent: "75%"
    },
    {
      text: "BNB Reflections Marketplace. Pancake Swap Application.",
      percent: "100%"
    },
  ];

  return (
    <Wrapper id="roadmap">
      <div className="globalWrapper">
        <H2 className="text-center">Roadmap</H2>
        <Content className="text-center">Official Roadmap for the GingerBreadBoys NFT Collection.</Content>
        <div className="grid">
          <div className="col-6_sm-12">
            <MapItemContainer className="grid grid-middle">
              <div className="col-2_sm-12"><Diamond><p>{roadmapArray[0].percent}</p></Diamond></div>
              <div className="col-1_sm-12"></div>
              <p className="col-9_sm-12">{roadmapArray[0].text}</p>
            </MapItemContainer>
            <MapItemContainer className="grid grid-middle">
              <div className="col-2_sm-12"><Diamond><p>{roadmapArray[1].percent}</p></Diamond></div>
              <div className="col-1_sm-12"></div>
              <p className="col-9_sm-12">{roadmapArray[1].text}</p>
            </MapItemContainer>
            <MapItemContainer className="grid grid-middle">
              <div className="col-2_sm-12"><Diamond><p>{roadmapArray[2].percent}</p></Diamond></div>
              <div className="col-1_sm-12"></div>
              <p className="col-9_sm-12">{roadmapArray[2].text}</p>
            </MapItemContainer>
          </div>
          <div className="col-6_sm-12_sm-12">
            <MapItemContainer className="grid grid-middle">
              <div className="col-2_sm-12"><Diamond><p>{roadmapArray[3].percent}</p></Diamond></div>
              <div className="col-1_sm-12"></div>
              <p className="col-9_sm-12">{roadmapArray[3].text}</p>
            </MapItemContainer>
            <MapItemContainer className="grid grid-middle">
              <div className="col-2_sm-12"><Diamond><p>{roadmapArray[4].percent}</p></Diamond></div>
              <div className="col-1_sm-12"></div>
              <p className="col-9_sm-12">{roadmapArray[4].text}</p>
            </MapItemContainer>
            <MapItemContainer className="grid grid-middle">
              <div className="col-2_sm-12"><SpecialDiamond><p>{roadmapArray[5].percent}</p></SpecialDiamond></div>
              <div className="col-1_sm-12"></div>
              <p className="col-9_sm-12">{roadmapArray[5].text}</p>
            </MapItemContainer>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default RoadMap;
