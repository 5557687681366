import styled from "styled-components";
import twitter from "../../images/twitter.png";
import telegram from "../../images/telegram.png";
import discord from "../../images/discord.png";

const Wrapper = styled.div`
background-color: #D0D0D0;
  padding: 8rem 0;


  @media all and (max-width: 1023px) {
    padding: 5rem 0;
  }
`;

const H3 = styled.h3`
  margin-bottom: 1rem;
`

const H4 = styled.h4`
  margin-bottom: 1rem;
`

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 1.5rem;
  display: inline-block;
  vertical-align: middle;
  width: auto;
`

const Links = styled.div`
  a {
    display: block;
    text-decoration: none;
    color: var(--primary);

    &:hover {
      text-decoration: underline;
    }
  }
`

const Copyright = styled.div`
  color: #fff;
  padding: 1rem 0;
  text-align: center;
  background-color: #000;
  font-family: "Century-gothic-bold", sans-serif;
  font-size: 1.3rem;
`

const Footer = () => {
  return (
    <>
      <Wrapper id="footer">
        <div className="globalWrapper">
          <div className="grid">
            <div className="col-5_md-12">
              <H3>GingerBreadBoys</H3>
              <p>Keep up to date with the GingerBreadBoys family across our social media platforms!</p>
              <div className="social-container">
                <a href="https://twitter.com/GingerBreadBSC" target="_blank" rel="noreferrer noopener"><Icon src={twitter} alt="#" /></a>
                <a href="https://t.me/gingerbreadboysbsc" target="_blank" rel="noreferrer noopener"><Icon src={telegram} alt="#" /></a>
                <a href="https://discord.gg/YYX7h8qeQ3" target="_blank" rel="noreferrer noopener"><Icon src={discord} alt="#" /></a>
              </div>
            </div>
            <div className="col-3_md-12"></div>
            <Links className="col-4_md-12">
              <H4>Links</H4>
              <a href="#about">About</a>
              <a href="#mint">Mint</a>
              <a href="#roadmap">Roadmap</a>
              <a href="#team">Team</a>
            </Links>
          </div>
        </div>
      </Wrapper>
      <Copyright>&copy; 2021 GINGERBREADBSC.</Copyright>
    </>
  );
};

export default Footer;
