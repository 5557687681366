import HeroSection from "../Components/HeroSection/HeroSection";
import About from "../Components/About/About";
import BakeNow from "../Components/BakeNow/BakeNow";
import RoadMap from "../Components/RoadMap/RoadMap";
import Info from "../Components/Info/Info";
import Team from "../Components/Team/Team";
import Footer from "../Components/Footer/Footer";
import styled from "styled-components";

export const Spacer = styled.div`
  background-color: #fff;
  height: 8rem;

  @media (max-width: 1023px) {
    margin-bottom: -10px;
    margin-top: -10px;
    height: 5rem;
  }
`

const Home = () => {
  return (
    <>
      <HeroSection />
      <BakeNow />
      {/* <Spacer /> */}
      {/* <About /> */}
      {/* <Spacer /> */}
      {/* <Info /> */}
      {/* <Spacer /> */}
      {/* <RoadMap /> */}
      {/* <Spacer /> */}
      {/* <Team /> */}
      {/* <Footer /> */}
    </>
  );
};

export default Home;
